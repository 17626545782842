import React from "react"
import Style from "./folding-flow.module.scss"

const FoldingFlow = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    let className = Style.flow
    className += this.state.isOpen ? ` ${Style.open}` : ""
    className += this.props.flowend ? ` ${Style.flowend}` : ""
    let onClick = this.props.children ? this.toggle : () => {}
    return (
      <dl className={className} onClick={onClick}>
        <dt className={Style.title}>{this.props.title}</dt>
        {this.props.children && (
          <svg
            className={Style.icon}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <line className={Style.line} id={Style.line1} x2="20" />
            <line
              className={Style.line}
              id={Style.line2}
              x1="20"
              transform="rotate(90)"
            />
          </svg>
        )}
        <dd className={Style.content}>{this.props.children}</dd>
      </dl>
    )
  }
}

export default FoldingFlow
