import React from "react"
import { Link } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import LinkButton from "../components/link-button"
import FoldingFlow from "../components/folding-flow"
import ContactInduction from "../components/contact-induction"
import Style from "./system.module.scss"

const Websites = class extends React.Component {
  render() {
    return (
      <PageLayout
        title="システム開発"
        enTitle="SYSTEM"
      >
        <SEO title="システム開発" />
        <div className={Style.system}>
          <p>
            業務に最適なシステムを開発するためには、システムの導入目的をお客様と共有し、二人三脚で造り上げていくことが重要であると考えます。当社はこれまでの開発、運用に携わってきた経験とノウハウを活かし、お客様の組織パフォーマンス向上に貢献できる最適なシステムを提案、開発してまいります。
          </p>
          <h2 id="can-do">自由度の高い独立系企業だからできること</h2>
          <p>
            当社は独立系の小さなシステム開発会社ですが、少人数だからこそ実現できる自由度の高さとフットワークの軽さを活かすことで、お客様のためだけに開発業務を集中させることができます。
          </p>
          <h2 id="merit">独自にシステムを開発して導入するメリット</h2>
          <p>
            システムを独自に開発して導入するメリットは、業務プロセスの標準化と効率化が図れるほか、情報の共有や管理がしやすくなることで内部統制上も業務の有効性が高まり、最終的には組織目標や経営目標の達成に結びつくようにオーダーメイドできることです。また、状況や環境の変化に伴うカスタマイズにも柔軟に対応できることも、独自に開発・導入されたシステムならではのメリットです。
          </p>
          <h2 id="introduction-support">導入支援で稼働開始時の不安を解消</h2>
          <p>
            当社ではお客様がシステムの稼働開始後からその効果を測定できるように、また、システムの利用に関する不安が解消されるように、本稼働前に操作説明などをはじめとする導入支援を実施しております。
          </p>
          <small>
            ※
            支援内容はシステムの形態および構成、規模、利用範囲、運用体制などにより異なります。
          </small>
          <h2 id="maintenance">導入後も安心のシステム保守</h2>
          <p>
            保守はシステムを安定稼働させるだけでなく、環境の変化に対応させるために必要不可欠です。
            当社はお客様が安心してシステムをご利用いただけるように、導入後もお客様に代わって各種メンテナンスやお問い合わせへの対応を実施しております。
          </p>
          <small>
            ※ システム保守は別途保守契約が必要となります。
            <br />
            ※ 保守は当社が開発、納入したシステムのみとさせていただいております。
            <br />
            ※
            改善要望に伴うプログラムおよびデータの改修は、保守契約外として別途ご契約となります。
            <br />
            ※
            お問合せ対応の対象はお客様社内のユーザー様（一次ユーザー様）に限らせていただきます。
            <br />※
            派遣型（お客様先常駐）の保守はお受けいたしかねますので予めご了承ください。
          </small>
          <h2 id="flow">システム開発の流れ</h2>
          <FoldingFlow title="ヒアリング">
            業務上の課題やご要望をお伺いし、適切なご提案と全体の概算を提示いたします。また、要件定義にかかる費用のお見積りを提示いたします。
          </FoldingFlow>
          <FoldingFlow title="要件定義">
            ヒアリングの結果から、システムの内容や規模、運用方法に至るまでを具体的に決定していきます。
            要件定義終了時に開発全体にかかる費用のお見積りを提示いたします。
          </FoldingFlow>
          <FoldingFlow title="外部設計（基本設計）">
            要件定義に基づいて、機能やデータの構成、ユーザーインターフェースなど、システム全体と各部分の大枠を設計します。
          </FoldingFlow>
          <FoldingFlow title="内部設計（詳細設計）">
            外部設計の内容を細分化して、プログラムが作成できるように設計します。
          </FoldingFlow>
          <FoldingFlow title="製造（プログラミング）">
            内部設計をもとにプログラム言語を用いてプログラムの作成を行います。
          </FoldingFlow>
          <FoldingFlow title="テスト">
            要件定義・設計通りに動作するか弊社内でテストします。
          </FoldingFlow>
          <FoldingFlow title="導入・運用テスト">
            実際にお客様の環境で利用していただき、要件定義・設計通りのシステムになっているかを総合的に検証します。
          </FoldingFlow>
          <FoldingFlow title="本稼働" flowend="true">
            運用テストをパスしたら、いよいよ本稼働の開始です。
            当社では本稼働開始より保守を承っております。ぜひご検討ください。
          </FoldingFlow>
          <h2 id="consultation">まずはご相談ください</h2>
          <p>
            システム開発・導入に対するご要望やご期待は、会社全体にかかわるものから業務単位にいたるまで各種様々です。
            文書を電子化してスマートに管理したいといったことから、この一部分だけ自動化できないかといったことまで、まずはご相談ください。
          </p>
          <ContactInduction />

          <h2 id="faq">FAQ</h2>
          <ul className={Style.faq}>
            <li>
              <p className={Style.question}>
                Ｑ：既存のシステムの改修は出来ますか？
              </p>
              <p className={Style.answer}>
                Ａ：弊社が開発・納入したシステムであれば、保守または別途お見積りにて承ります。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：システム開発を依頼するにあたって準備しておくものはありますか？
              </p>
              <p className={Style.answer}>
                Ａ：現状の課題や業務の流れをまとめておいて頂けるとスムーズに進めることが出来ます。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：開発にかかる費用はどのタイミングで分かりますか？
              </p>
              <p className={Style.answer}>
                Ａ：ヒアリングのタイミングで概算費用と要件定義にかかるお見積り額を提示いたします。開発工程以降のお見積りは要件定義の完了後に提示いたします。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：契約のタイミングはいつですか？
              </p>
              <p className={Style.answer}>
                Ａ：基本的には要件定義の前と、開発工程（外部設計以降）の前の2段階になります。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：契約形態はどのようになりますか？
              </p>
              <p className={Style.answer}>
                Ａ：要件定義から設計工程は準委任契約、製造からテストは請負契約を基本としています。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：システム開発後の保守はしてもらえますか？
              </p>
              <p className={Style.answer}>
                Ａ：保守契約を締結いただくことにより対応いたします。
                <br />→ <Link to="#maintenance">保守契約について</Link>
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：支払いはどのタイミングでいつまでにすればよいのですか？
              </p>
              <p className={Style.answer}>
                Ａ：お支払いについてはご契約前にお客様とのお話合いにより決定いたします。
              </p>
            </li>
          </ul>
          <ContactInduction />
        </div>
      </PageLayout>
    )
  }
}

export default Websites
