import React from "react"
import { Link } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./privacy-policy.module.scss"

const PrivacyPolicy = class extends React.Component {
  render() {
    return (
      <PageLayout
        title="個人情報保護方針"
        enTitle="PRIVACY POLICY"
      >
        <SEO title="個人情報保護方針" />
        <div className={Style.privacyPolicy}>
          <h2>基本方針</h2>
          <div className={Style.wrapper}>
            <p>
              合同会社Next2K（以下、当社）は、お客様からお預かりした個人情報の取扱いにおいて、その重要性を認識することと、個人情報に関する法令及び社内規程等を遵守し、以下の保護方針に基づいて個人情報を取り扱い、その保護に努めます。
            </p>
          </div>
          <h2>個人情報取扱事業者の名称</h2>
          <div className={Style.wrapper}>
            <p>
              合同会社Next2K
              <br />
              本社所在地：川崎市麻生区万福寺3-11-1
              <br />
              代表者氏名：日下部　仁
            </p>
          </div>
          <h2>個人情報の取得</h2>
          <div className={Style.wrapper}>
            <p>
              当社は、お客様の個人情報（氏名、住所、メールアドレス、電話番号など個人を特定できる情報）を取得する場合、以下の手段により取得いたします。
            </p>
            <ol>
              <li>お客様との直接の連絡による取得</li>
              <li>
                当社Webサイトのお問合せフォームからの取得（住所・氏名・メールアドレス・電話番号）
              </li>
              <li>第三者を介しての取得</li>
            </ol>
          </div>
          <h2>個人情報の利用目的</h2>
          <div className={Style.wrapper}>
            <p>
              当社は、お客様の個人情報を以下の目的のために利用いたします。今後以下に定める目的以外の利用が必要になる場合には、事前にお客様に利用目的を通知します。
            </p>
            <ol>
              <li>事務に関する処理</li>
              <li>取引に関するお客様との連絡</li>
              <li>その他重要事項等のお客様への連絡</li>
            </ol>
          </div>
          <h2>個人情報の安全管理</h2>
          <div className={Style.wrapper}>
            <p>
              当社は、お客様の個人情報へのアクセスを当社業務に従事する役員および従業員に限定し、管理責任者を定め、以下に基づいて適切な管理を行います。
            </p>
            <ol>
              <li>
                個人情報の正確性の確保
                <br />
                お客様からご提供いただいた個人情報については、常に正確かつ最新の情報となるよう努めます。
              </li>
              <li>
                安全管理措置
                <br />
                当社は、組織的な個人情報の管理については、社内規定により厳重に取扱い方法を規定し、それに基づいた取扱いを徹底しています。
              </li>
              <li>
                従業者の監督
                <br />
                当社は、当社規程に基づき、個人情報取扱い規程の厳格な運用を徹底しています。
              </li>
              <li>
                委託先の監督
                <br />
                個人情報の取扱いを外部に委託する場合には、当社規程に基づき、用件を満たした委託先にのみ委託を行い、適切な管理を行います。
              </li>
              <li>
                保存期間と廃棄
                <br />
                お客様からご提供いただいた個人情報については、保存期間を設定し、保存期間終了後は廃棄します。また、保存期間内であっても、不要となった場合やお客様からのお手続きがあった場合には速やかに廃棄します。なお、年単位等の長期契約のお客様の個人情報については保存期間満了後でも契約終了まで継続して保有する場合があります。
              </li>
            </ol>
          </div>
          <h2>第三者への開示・提供の禁止</h2>
          <div className={Style.wrapper}>
            <p>
              当社は、お客様よりご提供いただいた個人情報を第三者に開示、提供いたしません。今後第三者提供を行う必要が生じた場合には、事前にお客様に対して提供する情報と提供目的等を提示し、お客様から同意を得た場合のみ第三者提供を行います。但し、以下の場合にはその限りではありません。
            </p>
            <ol>
              <li>
                個人情報の保護に関する法律第２３条第１項各号により、法令に基づいて提供する場合
              </li>
              <li>
                個人情報を含む業務を委託するため、委託先の法人又は個人に、機密保持契約の事前締結を条件として情報を提供する場合
              </li>
            </ol>
          </div>
          <h2>個人情報の開示・訂正・利用停止等</h2>
          <div className={Style.wrapper}>
            <p>
              当社はお客様から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。また、個人情報の内容の訂正や利用停止等についても、お客様の申請に基づき、速やかに処理します。
            </p>
            <ol>
              <li>
                保有個人情報の訂正等
                <br />
                ご提供いただいた個人情報に訂正が必要な場合は、
                <Link to="/contact/">お問合せフォーム</Link>
                より訂正内容を記入、ご連絡いただくことで情報の更新を行うことができます。
              </li>
              <li>
                保有個人情報の利用停止等
                <br />
                ご提供いただいた個人情報の利用停止、ご提供の停止、削除等については、
                <Link to="/contact/">お問合せフォーム</Link>
                より利用停止、ご提供の停止、削除の旨ご連絡いただくことで行うことができます。
              </li>
              <li>
                手数料
                <br />
                上記の手続きには、手数料等は発生いたしません。
              </li>
            </ol>
          </div>
          <h2>個人情報に関する問い合わせ</h2>
          <div className={Style.wrapper}>
            <p>
              個人情報の取扱いに関するお問い合せは、
              <Link to="/contact/">お問合せフォーム</Link>
              よりご連絡をお願いいたします。
            </p>
          </div>
          <h2>個人情報の取扱いに関する相談・苦情</h2>
          <div className={Style.wrapper}>
            <p>
              個人情報の取扱いに関する相談・苦情は、
              <Link to="/contact/">お問合せフォーム</Link>
              よりご連絡をお願いいたします。
            </p>
          </div>
          <h2>暗号化通信（SSL：Secure Socket Layer）について</h2>
          <div className={Style.wrapper}>
            <p>
              当社のＷebサイトはSSLに対応しており、WebブラウザおよびWebサーバ間で暗号化された通信を行っています。お客様がお問合せフォームより入力する氏名・住所・連絡先等の個人情報は自動的に暗号化されます。
            </p>
          </div>
          <h2>Google AnalyticsおよびCookieの利用について</h2>
          <div className={Style.wrapper}>
            <p>
              当社では、当社Webサイトの利用状況を分析するためにGoogle
              AnalyticsおよびCookieを利用しています。なお、Google
              Analyticsの利用により収集されるCookieは、Google
              Inc.のプライバシーポリシーにより管理されています。Google
              Analyticsの詳細については「
              <a
                href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                target="_blank"
                rel="noreferrer"
              >
                Google Analytics利用規約
              </a>
              」「
              <a
                href="https://policies.google.com/privacy?hl=ja"
                target="_blank"
                rel="noreferrer"
              >
                Googleプライバシーポリシー
              </a>
              」をご確認ください。
            </p>
            <p>
              Cookie名称：Google Analytics
              <br />
              ツール提供者：Google Inc.
              <br />
              Cookieの種類：分析用Cookie
            </p>
          </div>
          <h2>個人情報保護方針の変更</h2>
          <div className={Style.wrapper}>
            <p>
              当社は、法令の変更に伴う場合、または個人情報の扱いに関して重要な変更がある場合は、本個人情報保護方針を変更し、当社のWebサイト上に掲載する方法によりお知らせ致します。
            </p>
          </div>
          <h2>免責事項</h2>
          <div className={Style.wrapper}>
            <ol>
              <li>
                当社Webサイトの利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
              </li>
              <li>
                当社Webサイトの利用者が当社Webサイトを利用したことにより生じた利用者自身への損害及び利用者が第三者に与えた損害に関して、当社は一切の責任を負わないものとします。{" "}
              </li>
            </ol>
          </div>
          <h2>改訂</h2>
          <div className={Style.wrapper}>
            <p>
              2022年 4月 1日　策定
              <br />
              2022年 9月 1日　改訂
            </p>
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default PrivacyPolicy
