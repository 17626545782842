import React from "react"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./about.module.scss"

const About = class extends React.Component {
  render() {
    return (
      <PageLayout
        title="会社概要"
        enTitle="ABOUT"
      >
        <div className={Style.about}>
          <SEO title="会社概要" />
          <table className={Style.aboutTable}>
            <tbody>
              <tr>
                <th>会社名</th>
                <td>
                  合同会社Next2K（ゴウドウガイシャ　ネクストツーケー）
                  <br />
                  英語表記名　Next2K.llc（Next2K Limited Liability Company）
                </td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>〒215-0004 川崎市麻生区万福寺3-11-1 </td>
              </tr>
              <tr>
                <th>代表社員</th>
                <td>日下部　仁</td>
              </tr>
              <tr>
                <th>設立日</th>
                <td>2019年7月19日</td>
              </tr>
              <tr>
                <th>取引銀行</th>
                <td>三菱UFJ銀行・ゆうちょ銀行</td>
              </tr>
              <tr>
                <th>事業内容</th>
                <td>システム開発・Webサイト制作</td>
              </tr>
              <tr>
                <th>WEBサイト</th>
                <td>https://www.next2k.com/</td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td>info@next2k.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </PageLayout>
    );
  }
}

export default About
