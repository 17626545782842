import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import PageLayout from "../components/page-layout"
import NewsItem from "../components/news-item"
import SEO from "../components/seo"
import Style from "./news.module.scss"

const News = class extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
      <PageLayout
        title="お知らせ"
        enTitle="NEWS"
      >
        <div className={Style.news}>
          <SEO title="お知らせ" />
          {this.props.data.allMarkdownRemark.edges.map(({ node }) => (
            <NewsItem
              date={node.frontmatter.date}
              title={node.frontmatter.title}
              slug={node.fields.slug}
              key={node.id}
            />
          ))}
          <div className={Style.pagenation}>
            <div className={Style.prev}>
              {!isFirst && <Link to={`/news/${prevPage}`}>前</Link>}
            </div>
            {Array.from({ length: numPages }, (_, i) => {
              if (currentPage !== i + 1) {
                return (
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={`/news/${i === 0 ? "" : i + 1}`}
                  >
                    {i + 1}
                  </Link>
                )
              } else {
                return <span key={`pagination-number${i + 1}`}>{i + 1}</span>
              }
            })}
            <div className={Style.next}>
              {!isLast && <Link to={`/news/${nextPage}`}>次</Link>}
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export const query = graphql`query ($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        frontmatter {
          title
          date(formatString: "yyyy.MM.DD")
        }
        fields {
          slug
        }
        id
      }
    }
  }
}
`

export default News
