import React from "react"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import LinkButton from "../components/link-button"
import FoldingFlow from "../components/folding-flow"
import ContactInduction from "../components/contact-induction"
import Style from "./websites.module.scss"

const Websites = class extends React.Component {
  render() {
    return (
      <PageLayout
        title="Webサイト制作"
        enTitle="WEBSITES"
      >
        <SEO title="Webサイト制作" />
        <div className={Style.websites}>
          <p>
            お客様の目的に合ったデザイン・運用をご提案いたします。
            Webサイトやデザインの知識がなくてもご安心ください。親切丁寧を心がけ、わかりやすく説明いたします。
          </p>
          <h2 className={Style.respondToRequests}>
            こんなご要望にお応えします！
          </h2>
          <ul className={Style.requests}>
            <li>ホームページを開設したい</li>
            <li>自分でサイトを更新したい</li>
            <li>スマートフォンにも対応したサイトがほしい</li>
            <li>SNSと連動したサイトがほしい</li>
            <li>今のサイトをリニューアルしたい</li>
            <li>集客率を高めたい</li>
          </ul>
          <h2 id="production">制作について</h2>
          <p>
            当社で制作するWebサイトは、パソコン・スマートフォン・タブレットなどの各種デバイスからでも見やすくて使いやすいレスポンシブデザイン、Googleなどの検索エンジンによる検索結果として上位表示させることで集客効果が期待できるSEO対策を含めて制作しております。また、オプションとしてCMSの導入やSNSとの連動機能をご用意しております。
          </p>
          <h3>CMS導入（オプション）</h3>
          <p>
            Webページの記述言語であるHTMLやCSSを知らなくても、お客様ご自身で簡単にWebサイトの更新や管理ができるようにCMS（Wordpress）を導入して制作いたします。CMSを導入して制作されたWebサイトは、いつでもお客様のご都合で更新できるだけでなく、作業を外注しなくて済むためランニングコストの削減にもつながります。
          </p>
          <h3>SNS連動（オプション）</h3>
          <p>
            InstagramやFacebook、TwitterなどのSNSと連動させることで、Webサイト上からでもタイムリーに情報を発信できるようになります。また、Webサイトが更新された際に自動でお知らせをSNSへ投稿することもできます。
          </p>
          <h2 id="others">その他</h2>
          <p>
            初めてWebサイトを開設する場合、事前にサーバをレンタルしたりドメイン(～.comや～.co.jpなど)を用意しておく必要があります。どの会社のサーバをレンタルすれば良いのかわからない、ドメインの取得方法がわからないといった場合でもご安心ください。当社ではレンタルサーバの契約手続き代行、ドメインの取得代行も承っております。
          </p>
          <small>※ レンタルサーバ、ドメインはお客様の名義となります。</small>
          <h2 id="flow">Webサイト制作の流れ</h2>
          <FoldingFlow title="ヒアリング">
            Webサイトの目的やイメージ、ご希望のコンテンツなどのご要望をお伺いし、デザインの方向性などを決定します。
          </FoldingFlow>
          <FoldingFlow title="お見積り">
            ヒアリングした内容をもとに費用をお見積りします。
          </FoldingFlow>
          <FoldingFlow title="設計（デザイン）">
            ヒアリングした内容をもとに掲載する情報を精査し、サイトの構成やデザインを決定します。
          </FoldingFlow>
          <FoldingFlow title="サイト構築">
            設計（デザイン）の内容をもとにサイトを構築します。
          </FoldingFlow>
          <FoldingFlow title="テスト">
            設計（デザイン）の内容をもとにテストを行います。
          </FoldingFlow>
          <FoldingFlow title="最終確認">
            サイトをお客様にご確認いただき、最終調整を行います。
          </FoldingFlow>
          <FoldingFlow title="公開" flowend="true">
            最終確認を終えたWebサイトをインターネット上に公開します。
          </FoldingFlow>
          <h2 id="after">公開後について</h2>
          <p>
            Webサイトは公開後も情報を更新・発信していくことが重要です。更新作業の時間が取れないお客様のため、保守契約（年間・スポット）による更新作業の代行も承っております。お気軽にご相談ください。
          </p>
          <p className={Style.freeQuote}>
            お見積りは<span className={Style.free}>無料</span>です。
            <span>お気軽にご相談ください！</span>
          </p>
          <h2 id="faq">FAQ</h2>
          <ul className={Style.faq}>
            <li>
              <p className={Style.question}>
                Ｑ：個人経営の店舗のような小規模事業でも制作してもらえますか？
              </p>
              <p className={Style.answer}>
                Ａ：もちろんです。経営規模の大小にかかわらずどうぞご遠慮なくご依頼ください。サポートについても同様です。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：Webサイト制作にあたって準備しておくもの、必要な事はありますか？
              </p>
              <p className={Style.answer}>
                Ａ：サイトの目的、コンセプトを明確にして頂けるとスムーズに進めることが出来ます。また、掲載する文言や写真は原則お客様にご用意していただいております。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：契約するまではどんな順序で進めるのですか？
              </p>
              <p className={Style.answer}>
                Ａ：まずはお客様のサイトの構成や内容、イメージなどの概ねのご要望を伺った時点で概算費用を提示いたします。さらに詳しくご要望を伺ったところでお見積りを提示させていただきます。お見積りにご納得いただけたところでご契約となります。なお、お見積りは何度でもお出しいたしますので、お客様がご納得いくまでお付き合いいたします。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：社内で見積り検討後、承認後はすぐに契約手続きをしなければなりませんか？
              </p>
              <p className={Style.answer}>
                Ａ：契約の履行開始日によります。履行開始日まであまりお時間がない案件の場合はできるだけお急ぎいただいたほうが無難ではありますが、ご契約内容だけはご確認くださいますようお願いいたします。
              </p>
            </li>
            <li>
              <p className={Style.question}>
                Ｑ：納品はどのような形式になるのですか？
              </p>
              <p className={Style.answer}>
                Ａ：納品は事前のお客様による最終確認をもってご指定の日時から公開されるように作業いたします。
              </p>
            </li>
          </ul>
          <ContactInduction />
        </div>
      </PageLayout>
    )
  }
}

export default Websites
