import { Link } from "gatsby"
import React from "react"
import style from "./header.module.scss"

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <header className={style.header}>
        <Link className={style.logo} to="/">
          NEXT2K
        </Link>
        <nav className={style.menuWrapper}>
          <ul className={`${style.menu} ${this.state.isOpen ? style.open : ""}`}>
            <li className={style.item}>
              <Link to="/news/">NEWS</Link>
            </li>
            <li className={style.item}>
              <Link to="/system/">SYSTEM</Link>
            </li>
            <li className={style.item}>
              <Link to="/websites/">WEBSITES</Link>
            </li>
            <li className={style.item}>
              <Link to="/contact/">CONTACT</Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header
