import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ContactInduction from "../components/contact-induction"
import NewsItem from "../components/news-item"
import SEO from "../components/seo"
import Style from "./index.module.scss"

const IndexPage = class extends React.Component {
  constructor(props) {
    super(props)
    this.window = React.createRef()
    this.state = {
      windowHeight: "",
    }
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight })
  }

  render() {
    return (
      <Layout>
        <SEO title="Top" />
        <div className={Style.top} style={{ height: this.state.windowHeight }}>
          <span role="img" aria-label="" className={Style.logo}></span>
        </div>
        <section className={Style.news}>
          <h1 id="news">NEWS</h1>
          {this.props.data.allMarkdownRemark.edges.map(({ node }) => (
            <NewsItem
              date={node.frontmatter.date}
              title={node.frontmatter.title}
              slug={node.fields.slug}
              key={node.id}
            />
          ))}
          <Link to="/news/" className={Style.readMore}>
            READ MORE
          </Link>
        </section>
        <ContactInduction />
      </Layout>
    )
  }
}

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "yyyy.MM.DD")
          }
          fields {
            slug
          }
          id
        }
      }
    }
  }
`

export default IndexPage
