import { Link } from "gatsby"
import React from "react"
import Style from "./news-item.module.scss"

const NewsItem = ({ date, title, slug }) => (
  <article className={Style.article}>
    <time className={Style.posted}>{date}</time>
    <Link to={slug} className={Style.link}>{title}</Link>
  </article>
)

export default NewsItem
