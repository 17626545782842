/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import "normalize.css"
import "./layout.css"
import Style from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`{
  desktop: file(relativePath: {eq: "background.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`}
      render={data => {
        const image = getImage(data.desktop.childImageSharp.gatsbyImageData)
        const bgImage = convertToBgImage(image)
        return (
          <div className={Style.background}>
            <Header />
            <div className={Style.main}>
              <main>{children}</main>
            </div>
            <Footer />
          </div>
        )
      }}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
