import { Link } from "gatsby"
import React from "react"
import style from "./footer.module.scss"

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
    this.window = React.createRef()
    this.state = {
      copyrightStyle: {
        width: "",
      },
    }
  }

  componentDidMount() {
    this.setState({
      copyrightStyle: {
        width: window.innerHeight,
      },
    })
    window.onresize = () => {
      this.setState({
        copyrightStyle: {
          width: "100vh",
        },
      })
    }
    window.onscroll = () => {
      if (this.scrollTop() === 0) {
        this.setState({
          copyrightStyle: {
            width: window.innerHeight,
          },
        })
      }
    }
  }

  scrollTop = () => {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
  }

  render() {
    return (
      <footer className={style.footer}>
        <h2>
          <Link to="/">NEXT2K</Link>
        </h2>
        <ul className={style.menu}>
          <li className={style.item}>
            <Link to="/about/">ABOUT</Link>
          </li>
          <li className={style.item}>
            <Link to="/privacy-policy/">PRIVACY POLICY</Link>
          </li>
          <li className={style.item}>
            <Link to="/sitemap/">SITE MAP</Link>
          </li>
        </ul>
        <p className={style.copyright}>
          © 2020-2022 Next2K llc.
        </p>
      </footer>
    )
  }
}

export default Footer
