import React from "react"
import { Link } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./sitemap.module.scss"

const Sitemap = class extends React.Component {
  render() {
    return (
      <PageLayout
        title="サイトマップ"
        enTitle="SITEMAP"
      >
        <SEO title="サイトマップ" />
        <div className={Style.sitemap}>
          <div className={Style.wrapper}>
            <ul className={Style.headingList}>
              <li>
                <Link to="/news/">お知らせ</Link>
              </li>
              <li>
                <Link to="/system/">システム開発</Link>
              </li>
              <li>
                <Link to="/websites/">Webサイト制作</Link>
              </li>
              <li>
                <Link to="/contact/">お問合せ</Link>
              </li>
              <li>
                <Link to="/about/">会社情報</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">個人情報保護方針</Link>
              </li>
            </ul>
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default Sitemap
